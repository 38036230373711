import Leaflet from "leaflet";

export const renderMarkerIcon = (isSelected: boolean) =>
  Leaflet.divIcon({
    className: isSelected ? "selected" : "",
    iconSize: [52, 52],
    popupAnchor: [0, -26],
    html: `
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="26" cy="26" r="26" fill="currentColor" fill-opacity="0.1"/>
        <circle cx="26" cy="26" r="10" fill="currentColor" stroke="white" stroke-width="2"/>
    </svg>
    `,
  });
