import { Image, Modal, ModalCloseButton, ModalContent } from "@chakra-ui/react";

type FilterModalProps = {
  isOpen: boolean;
  onClose: () => any;
  image: string;
};

export function ImageModal({ isOpen, onClose, image }: FilterModalProps) {
  return (
    <Modal size="full" isOpen={isOpen} onClose={onClose}>
      <ModalContent
        bg="blackAlpha.600"
        alignItems="center"
        justifyContent="center"
      >
        <ModalCloseButton color="white" />

        <Image src={image} maxHeight="85vh" maxWidth="90vw" objectFit="cover" />
      </ModalContent>
    </Modal>
  );
}
