import { useBreakpoint, useToast } from "@chakra-ui/react";
import { Map } from "leaflet";
import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import OutdoorsService, { Outdoor } from "../services/OutdoorsService";
import { useSearchParams } from "react-router-dom";

interface OutdoorProviderProps {
  children: ReactNode;
}

export type Filters = {
  search: string;
  boardType: string;
  neighborhood: string;
  city: string;
};

export interface OutdoorContextProps {
  mapRef: MutableRefObject<Map | null>;
  defaultPosition: [number, number];
  defaultZoom: number;
  isFirstRender: boolean;
  isLoading: boolean;
  outdoors: Outdoor[];
  selectedOutdoor: Outdoor | null;
  changeSelectedOutdoor: (selectedOutdoor: Outdoor | null) => any;
  searchValue: string;
  changeSearchValue: (searchValue: string) => void;
  isFilterModalVisible: boolean;
  setIsFilterModalVisible: Dispatch<SetStateAction<boolean>>;
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
  isFiltersEmpty: boolean;
  isMobile: boolean;
  isImageModalOpened: boolean;
  setIsImageModalOpened: Dispatch<SetStateAction<boolean>>;
  isOutdoorDetailsOpened: boolean;
  setIsOutdoorDetailsOpened: Dispatch<SetStateAction<boolean>>;
}

export const OutdoorContext = createContext<OutdoorContextProps>(
  {} as OutdoorContextProps
);

export function useOutdoor(): OutdoorContextProps {
  const context = useContext(OutdoorContext);

  if (!context)
    throw new Error("useOutdoor must be used within an OutdoorProvider");

  return context;
}

const defaultPosition: [number, number] = [
  -12.974482677554677, -38.44516318489264,
];
const defaultZoom = 13;

export const intialFilters: Filters = {
  search: "",
  boardType: "",
  city: "",
  neighborhood: "",
};

export function OutdoorProvider({ children }: OutdoorProviderProps) {
  const [searchParams] = useSearchParams();

  const [isFirstRender, setIsFirstRender] = useState(true);

  const toast = useToast();

  const breakpoint = useBreakpoint();
  const isMobile = ["base", "sm", "md"].includes(breakpoint);

  const mapRef = useRef<Map | null>(null);

  const [isImageModalOpened, setIsImageModalOpened] = useState(false);
  const [isOutdoorDetailsOpened, setIsOutdoorDetailsOpened] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [outdoors, setOutdoors] = useState<Outdoor[]>([]);
  const [selectedOutdoor, setSelectedOutdoor] = useState<Outdoor | null>(null);

  const [searchValue, setSearchValue] = useState<string>("");
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [filters, setFilters] = useState<Filters>(intialFilters);
  const isFiltersEmpty =
    (isMobile ? filters.search === "" : true) &&
    filters.city === "" &&
    filters.neighborhood === "" &&
    filters.boardType === "";

  function changeSearchValue(searchValue: string) {
    setSearchValue(searchValue);
  }

  function changeSelectedOutdoor(selectedOutdoor: Outdoor | null) {
    setSelectedOutdoor(selectedOutdoor);

    if (selectedOutdoor) {
      mapRef.current?.setView(
        [selectedOutdoor.latitude, selectedOutdoor.longitude],
        mapRef.current.getZoom()
      );
    }
  }

  useEffect(() => {
    const outdoorId = searchParams.get("outdoor");

    if (!outdoorId) return;

    const selectedOutdoor = outdoors.find(
      (outdoor) => outdoor.id === Number(outdoorId)
    );

    if (selectedOutdoor) {
      setSelectedOutdoor(selectedOutdoor);
      setIsOutdoorDetailsOpened(true);
    }
  }, [outdoors, searchParams]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const { data: outdoorsData } = await OutdoorsService.getOutdoors({
          ...filters,
          search: isMobile ? filters.search : searchValue,
        });

        setOutdoors(outdoorsData);

        setIsFirstRender(false);

        if (outdoorsData.length > 0) {
          toast.closeAll();
        } else {
          toast({
            status: "info",
            title: "Nenhum outdoor encontrado :(",
            description:
              "Não foi possível encontrar outdoors com os filtros selecionados.",
            isClosable: true,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, searchValue]);

  return (
    <OutdoorContext.Provider
      value={{
        mapRef,
        isFirstRender,
        isLoading,
        outdoors,
        defaultPosition,
        defaultZoom,
        selectedOutdoor,
        changeSelectedOutdoor,
        searchValue,
        changeSearchValue,
        isFilterModalVisible,
        setIsFilterModalVisible,
        filters,
        setFilters,
        isFiltersEmpty,
        isMobile,
        isImageModalOpened,
        setIsImageModalOpened,
        isOutdoorDetailsOpened,
        setIsOutdoorDetailsOpened,
      }}
    >
      {children}
    </OutdoorContext.Provider>
  );
}
