import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { IconType } from "react-icons";
import { HiOutlineClipboard, HiOutlineClipboardCheck } from "react-icons/hi";
import { copyTextToClipboard } from "../utils/copyTextToClipboard";

type InfoItemProps = {
  icon: IconType;
  label: string;
  content: string;
  showCopyButton?: boolean;
};

export function InfoItem({
  icon,
  label,
  content,
  showCopyButton,
}: InfoItemProps) {
  const toast = useToast();

  const [isCopied, setIsCopied] = useState(false);

  async function handleCopy() {
    try {
      await copyTextToClipboard(content);

      setIsCopied(true);

      toast({
        status: "success",
        title: "Coordenadas copiadas com sucesso!",
        description:
          "Agora é só ir em seu aplicativo de navegação e colar as coordenadas na barra de pesquisa :)",
        isClosable: true,
        onCloseComplete: () => {
          setIsCopied(false);
        },
      });
    } catch (error) {
      toast({
        status: "error",
        title: "Não foi possível copiar as coordenadas automaticamente!",
        description: "Por favor, selecione o texto e copie manualmente :(",
        isClosable: true,
      });
    }
  }

  return (
    <Flex>
      <Center w={8} h={8} bg="gray.100" color="gray.500" borderRadius="md">
        <Icon as={icon} boxSize={4} />
      </Center>

      <Box flex={1} ml={2}>
        <Text
          fontSize="xs"
          fontWeight={700}
          color="gray.500"
          textTransform="uppercase"
        >
          {label}
        </Text>

        <Text fontWeight={500} fontSize="md" color="gray.700">
          {content}
        </Text>
      </Box>

      {showCopyButton ? (
        <Button
          variant="outline"
          size="xs"
          colorScheme="blue"
          leftIcon={
            isCopied ? (
              <HiOutlineClipboardCheck size={16} />
            ) : (
              <HiOutlineClipboard size={16} />
            )
          }
          iconSpacing={1}
          alignSelf="center"
          ml={4}
          fontWeight={700}
          onClick={handleCopy}
        >
          {isCopied ? "Copiado" : "Copiar"}
        </Button>
      ) : null}
    </Flex>
  );
}
