import HttpClient from "./utils/HttpClient";

export type NeighborhoodType = {
  id: number;
  name: string;
};

class NeighborhoodsService {
  async listNeighborhoods() {
    return HttpClient.get<NeighborhoodType[]>("/neighborhood");
  }
}

export default new NeighborhoodsService();
