import { useEffect, useRef } from "react";

import {
  AspectRatio,
  Badge,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  BiBuildingHouse,
  BiHash,
  BiInfoCircle,
  BiMap,
  BiMapPin,
  BiPencil,
  BiPurchaseTag,
} from "react-icons/bi";

import { useOutdoor } from "../context/outdoor";

import { InfoItem } from "./InfoItem";

// @ts-ignore
import sampleImage from "../assets/img/sample.png";

import { customScrollBar } from "../styles/global";
import { getYoutubeVideoId } from "../utils/getYoutubeVideoId";
import { TbArrowsMaximize, TbX } from "react-icons/tb";
import { HiOutlineLink } from "react-icons/hi";
import { copyTextToClipboard } from "../utils/copyTextToClipboard";
import { useSearchParams } from "react-router-dom";

type OutdoorDetailsProps = {
  isOpen: boolean;
  onClose: () => any;
};

export function OutdoorDetails({ isOpen, onClose }: OutdoorDetailsProps) {
  const { selectedOutdoor, setIsImageModalOpened } = useOutdoor();

  const toast = useToast();
  const [, setSearchParams] = useSearchParams();

  const videoRef = useRef<HTMLVideoElement>(null);

  const outdoorLink = `${window.location.origin}/?outdoor=${selectedOutdoor?.id}`;
  const gpsCoordinates = `${selectedOutdoor?.latitude}, ${selectedOutdoor?.longitude}`;
  const streetViewUrl = `https://www.google.com/maps?q&layer=c&cbll=${selectedOutdoor?.latitude},${selectedOutdoor?.longitude}&cbp`;

  useEffect(() => {
    videoRef.current?.load();
  }, [selectedOutdoor]);

  function handleDrawerClose() {
    onClose();
    setSearchParams({ outdoor: "" });
  }

  async function handleOutdoorLink() {
    try {
      await copyTextToClipboard(outdoorLink);

      toast({
        status: "success",
        title: "Link do outdoor copiado com sucesso!",
        isClosable: true,
      });
    } catch (error) {
      toast({
        status: "error",
        title: "Não foi possível copiar o link do outdoor!",
        description:
          "Por favor, selecione a URL na barra de navegação e copie manualmente.",
        isClosable: true,
      });
    }
  }

  if (!selectedOutdoor) return null;

  return (
    <Drawer
      isOpen={selectedOutdoor !== null && isOpen}
      onClose={handleDrawerClose}
      size="sm"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader
          p={0}
          flexBasis={250}
          display="flex"
          flexDirection="column"
          bgImage={
            selectedOutdoor.image
              ? selectedOutdoor.image.sizes.medium_large
              : sampleImage
          }
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="center"
        >
          <Flex
            flex={1}
            flexDirection="column"
            p={5}
            alignItems="flex-start"
            justifyContent="flex-end"
            bgGradient="linear(to-t, gray.900, transparent)"
          >
            <Flex gap={2} position="absolute" top={2} right={3}>
              {selectedOutdoor.image ? (
                <IconButton
                  aria-label="Expandir foto"
                  title="Expandir foto"
                  size="sm"
                  variant="ghost"
                  colorScheme="blackAlpha"
                  icon={<TbArrowsMaximize color="white" size={16} />}
                  onClick={() => setIsImageModalOpened(true)}
                  _hover={{ bg: "var(--chakra-colors-blackAlpha-500)" }}
                />
              ) : null}

              <IconButton
                aria-label="Fechar"
                title="Fechar"
                size="sm"
                variant="ghost"
                colorScheme="blackAlpha"
                icon={<TbX color="white" size={18} />}
                onClick={handleDrawerClose}
                _hover={{ bg: "var(--chakra-colors-blackAlpha-500)" }}
              />
            </Flex>

            {selectedOutdoor.neighborhood && (
              <Badge rounded="full" px={2} fontSize="xs" colorScheme="blue">
                {selectedOutdoor.neighborhood.name}
              </Badge>
            )}

            <Heading mt={2} fontSize="xl" lineHeight="short" color="white">
              {selectedOutdoor.name}
            </Heading>
          </Flex>
        </DrawerHeader>

        <DrawerBody p={5} sx={customScrollBar}>
          <Flex
            justifyContent="space-between"
            pb={3}
            borderBottomColor="gray.300"
            borderBottomWidth={1}
          >
            <Text fontWeight={700} fontSize="lg" color="gray.600">
              Informações
            </Text>

            <Button
              variant="outline"
              size="xs"
              colorScheme="blue"
              leftIcon={<HiOutlineLink size={16} />}
              iconSpacing={1}
              alignSelf="center"
              ml={4}
              fontWeight={700}
              onClick={handleOutdoorLink}
            >
              Copiar link
            </Button>
          </Flex>

          <Flex flexDirection="column" mt={6} gap={5}>
            <InfoItem
              icon={BiHash}
              label="Código"
              content={selectedOutdoor.code}
            />

            <InfoItem
              icon={BiPencil}
              label="Nome"
              content={selectedOutdoor.name}
            />

            <InfoItem
              icon={BiInfoCircle}
              label="Ponto de Referência"
              content={selectedOutdoor.reference_point || ""}
            />

            {selectedOutdoor.city && (
              <InfoItem
                icon={BiMapPin}
                label="Coordenadas GPS"
                content={gpsCoordinates}
                showCopyButton={true}
              />
            )}

            {selectedOutdoor.neighborhood && (
              <InfoItem
                icon={BiBuildingHouse}
                label="Bairro"
                content={selectedOutdoor.neighborhood.name}
              />
            )}

            {selectedOutdoor.city && (
              <InfoItem
                icon={BiMap}
                label="Cidade"
                content={selectedOutdoor.city.name}
              />
            )}

            {selectedOutdoor.board_type && (
              <InfoItem
                icon={BiPurchaseTag}
                label="Tipo de Placa"
                content={selectedOutdoor.board_type.name}
              />
            )}

            {selectedOutdoor.video || selectedOutdoor.video_youtube ? (
              <AspectRatio ratio={16 / 9}>
                {selectedOutdoor.video_youtube ? (
                  <iframe
                    title={`${selectedOutdoor.code} - ${selectedOutdoor.name}`}
                    src={`https://www.youtube.com/embed/${getYoutubeVideoId(
                      selectedOutdoor.video_youtube
                    )}`}
                    allowFullScreen
                  />
                ) : selectedOutdoor.video ? (
                  <video ref={videoRef} controls>
                    <source
                      src={selectedOutdoor.video.url}
                      type={selectedOutdoor.video.mime_type}
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : null}
              </AspectRatio>
            ) : null}
          </Flex>

          <a href={streetViewUrl} target="_blank" rel="noreferrer">
            <Button
              variant="outline"
              size="lg"
              colorScheme="orange"
              mt={10}
              w="100%"
              fontSize="md"
            >
              Ver no Street View
            </Button>
          </a>

          <a href="mailto:alinhares@outdoor-ba.com.br">
            <Button
              size="lg"
              colorScheme="orange"
              mt={4}
              w="100%"
              fontSize="md"
            >
              Tenho interesse
            </Button>
          </a>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
