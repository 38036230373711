import HttpClient from "./utils/HttpClient";

export type CityType = {
  id: number;
  name: string;
};

class CitiesService {
  async listCities() {
    return HttpClient.get<CityType[]>("/city");
  }
}

export default new CitiesService();
