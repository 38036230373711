import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { BiSearch } from "react-icons/bi";

import { useOutdoor, Filters, intialFilters } from "../context/outdoor";

import CitiesService, { CityType } from "../services/CitiesService";
import NeighborhoodsService, {
  NeighborhoodType,
} from "../services/NeighborhoodsService";
import BoardTypesService, {
  BoardTypesType,
} from "../services/BoardTypesService";

type FilterModalProps = {
  isOpen: boolean;
  onClose: () => any;
};

type InitialValues = Filters & { search: string };

export function FilterModal({ isOpen, onClose }: FilterModalProps) {
  const { filters, setFilters, setIsFilterModalVisible, isFiltersEmpty } =
    useOutdoor();

  const [cities, setCities] = useState<CityType[]>([]);
  const [neighborhoods, setNeighborhoods] = useState<NeighborhoodType[]>([]);
  const [boardTypes, setBoardTypes] = useState<BoardTypesType[]>([]);

  const formik = useFormik<InitialValues>({
    initialValues: {
      search: filters.search || "",
      boardType: filters.boardType || "",
      city: filters.city || "",
      neighborhood: filters.neighborhood || "",
    },
    onSubmit: (values) => {
      setFilters(values);
      setIsFilterModalVisible(false);
    },
  });

  function clearFilters() {
    formik.resetForm();
    setFilters(intialFilters);
    onClose();
  }

  useEffect(() => {
    (async () => {
      const { data: citiesData } = await CitiesService.listCities();
      const { data: neighborhoodsData } =
        await NeighborhoodsService.listNeighborhoods();
      const { data: boardTypesData } = await BoardTypesService.listBoardTypes();

      setCities(citiesData);
      setNeighborhoods(neighborhoodsData);
      setBoardTypes(boardTypesData);
    })();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            fontWeight={700}
            py={6}
          >
            Filtrar outdoors
            <ModalCloseButton position="initial" />
          </ModalHeader>

          <ModalBody pb={6}>
            <FormControl display={{ base: "block", lg: "none" }} mb={6}>
              <FormLabel fontWeight={600}>Pesquisa</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={BiSearch} color="gray.500" />}
                />
                <Input
                  name="search"
                  placeholder="Pesquise por outdoors..."
                  focusBorderColor="orange.500"
                  fontWeight={500}
                  fontSize="sm"
                  value={formik.values.search}
                  onChange={formik.handleChange}
                />
              </InputGroup>
            </FormControl>

            <FormControl>
              <FormLabel fontWeight={600}>Cidade</FormLabel>
              <Select
                name="city"
                placeholder="Selecione uma cidade"
                focusBorderColor="orange.500"
                fontWeight={500}
                fontSize="sm"
                value={formik.values.city}
                onChange={formik.handleChange}
              >
                {cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mt={6}>
              <FormLabel fontWeight={600}>Bairro</FormLabel>
              <Select
                name="neighborhood"
                placeholder="Selecione um bairro"
                focusBorderColor="orange.500"
                fontWeight={500}
                fontSize="sm"
                value={formik.values.neighborhood}
                onChange={formik.handleChange}
              >
                {neighborhoods.map((neighborhood) => (
                  <option key={neighborhood.id} value={neighborhood.id}>
                    {neighborhood.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mt={6}>
              <FormLabel fontWeight={600}>Tipo de Placa</FormLabel>
              <Select
                name="boardType"
                placeholder="Selecione um tipo de placa"
                focusBorderColor="orange.500"
                fontWeight={500}
                fontSize="sm"
                value={formik.values.boardType}
                onChange={formik.handleChange}
              >
                {boardTypes.map((boardType) => (
                  <option key={boardType.id} value={boardType.id}>
                    {boardType.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" colorScheme="orange" mr={3}>
              Filtrar
            </Button>
            <Button onClick={isFiltersEmpty ? onClose : clearFilters}>
              {isFiltersEmpty ? "Cancelar" : "Limpar filtros"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
