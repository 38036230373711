import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

class HttpClient {
  private httpClient: AxiosInstance;
  private baseUrl?: string;

  constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL;
    this.httpClient = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
  }

  async get<T>(path: string, config?: AxiosRequestConfig) {
    return this.httpClient.get<T>(path, config);
  }

  async post<T>(path: string, body?: any, config?: AxiosRequestConfig) {
    return this.httpClient.post<T>(path, body, config);
  }

  async put<T>(path: string, body?: any, config?: AxiosRequestConfig) {
    return this.httpClient.put<T>(path, body, config);
  }

  async delete<T>(path: string, config?: AxiosRequestConfig) {
    return this.httpClient.delete<T>(path, config);
  }

  async getFetch<T>(path: string) {
    const response = await fetch(`${this.baseUrl}${path}`);
    const text = await response.text();

    const data = text.split("</script>")[text.split("</script>").length - 1];

    return JSON.parse(data) as T;
  }
}

export default new HttpClient();
