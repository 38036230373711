import { SystemStyleObject } from "@chakra-ui/react";

export const customScrollBar: SystemStyleObject = {
  "::-webkit-scrollbar": {
    width: "4px",
  },

  "::-webkit-scrollbar-track": {
    background: "transparent",
  },

  "::-webkit-scrollbar-thumb": {
    background: "var(--chakra-colors-gray-400)",
    borderRadius: "8px",
  },
};
