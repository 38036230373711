import { Badge, Box, Flex, Image, Text, FlexProps } from "@chakra-ui/react";

import { useOutdoor } from "../context/outdoor";

import { Outdoor } from "../services/OutdoorsService";

// @ts-ignore
import sampleImage from "../assets/img/sample.png";

type OutdoorCardProps = FlexProps & {
  outdoor: Outdoor;
};

export function OutdoorCard({ outdoor, ...props }: OutdoorCardProps) {
  const { selectedOutdoor, changeSelectedOutdoor } = useOutdoor();

  const isSelected = selectedOutdoor?.id === outdoor.id;

  return (
    <Flex
      as="button"
      flexDirection="column"
      w="100%"
      maxW="330px"
      borderWidth="1px"
      rounded="lg"
      bg="white"
      textAlign="left"
      transition="ease"
      transitionDuration="0.3s"
      borderColor={isSelected ? "blue.500" : "inherit"}
      _hover={{
        shadow: "lg",
      }}
      {...props}
      onClick={() => changeSelectedOutdoor(outdoor)}
    >
      <Image
        src={outdoor.image ? outdoor.image.sizes.medium_large : sampleImage}
        alt={outdoor.image?.alt}
        w="100%"
        height={44}
        objectFit="cover"
        roundedTop="lg"
      />

      <Box p={5}>
        {outdoor.neighborhood && (
          <Badge
            rounded="full"
            mb={1}
            px={2}
            fontWeight={600}
            fontSize="xs"
            colorScheme="blue"
          >
            {outdoor.neighborhood.name}
          </Badge>
        )}

        <Text
          fontSize="lg"
          fontWeight={700}
          lineHeight="short"
          color="gray.700"
        >
          {outdoor.name}
        </Text>
      </Box>
    </Flex>
  );
}
