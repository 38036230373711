import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import { OutdoorProvider } from "./context/outdoor";

import Home from "./pages/Home";

import { theme } from "./styles/theme";

// Importando estilo do Leaflet
import "leaflet/dist/leaflet.css";

// Importando fontes
import "@fontsource/raleway/400.css";
import "@fontsource/raleway/500.css";
import "@fontsource/raleway/600.css";
import "@fontsource/raleway/700.css";

function App() {
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <OutdoorProvider>
          <Home />
        </OutdoorProvider>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
