import { useRef, useEffect } from "react";
import { Button, Flex, Heading, Image } from "@chakra-ui/react";
import { Marker as LeafletMarker } from "leaflet";
import { Marker, Popup, MarkerProps } from "react-leaflet";

import { useOutdoor } from "../context/outdoor";

import { Outdoor } from "../services/OutdoorsService";

import { renderMarkerIcon } from "../utils/mapMarkers";

// @ts-ignore
import sampleImage from "../assets/img/sample.png";

type CustomMarkerProps = Omit<MarkerProps, "position"> & {
  outdoor: Outdoor;
  onButtonClick: () => any;
};

export function CustomMarker({
  outdoor,
  onButtonClick,
  ...props
}: CustomMarkerProps) {
  const { selectedOutdoor, changeSelectedOutdoor } = useOutdoor();

  const markerRef = useRef<LeafletMarker | null>(null);

  const isActive = selectedOutdoor?.id === outdoor.id;

  useEffect(() => {
    if (markerRef.current === null) return;

    if (isActive) {
      markerRef.current.openPopup();
    }
  }, [isActive, markerRef]);

  return (
    <Marker
      ref={markerRef}
      position={[outdoor.latitude, outdoor.longitude]}
      icon={renderMarkerIcon(isActive)}
      eventHandlers={{
        click: () => changeSelectedOutdoor(outdoor),
      }}
      {...props}
    >
      <Popup className="mapPopup" closeButton={false}>
        <Flex
          flexDirection="column"
          alignItems="center"
          w={240}
          p={4}
          textAlign="center"
        >
          <Image
            src={outdoor.image ? outdoor.image.sizes.medium_large : sampleImage}
            w="100%"
            h={240 / 2}
            objectFit="cover"
            borderRadius="lg"
          />

          <Heading fontSize="md" color="gray.700" mt={4}>
            {outdoor.name}
          </Heading>

          <Button
            variant="solid"
            size="md"
            colorScheme="orange"
            w="100%"
            mt={4}
            fontWeight={700}
            fontSize="xs"
            fontFamily="Raleway, sans-serif"
            onClick={onButtonClick}
          >
            Ver detalhes
          </Button>
        </Flex>
      </Popup>
    </Marker>
  );
}
