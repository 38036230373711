import { Filters } from "../context/outdoor";

import { CityType } from "./CitiesService";
import { NeighborhoodType } from "./NeighborhoodsService";
import { BoardTypesType } from "./BoardTypesService";

import HttpClient from "./utils/HttpClient";

type ImageType = {
  id: number;
  title: string;
  url: string;
  alt?: string;
  mime_type: string;
  sizes: {
    medium: string;
    medium_large: string;
    large: string;
    "1536x1536": string;
    "2048x2048": string;
  };
};

type VideoType = Omit<ImageType, "sizes">;

export type Outdoor = {
  id: number;
  code: string;
  name: string;
  latitude: number;
  longitude: number;
  city: CityType | null;
  neighborhood: NeighborhoodType | null;
  reference_point: string;
  board_type: BoardTypesType | null;
  image: ImageType | null;
  video: VideoType | null;
  video_youtube: string | null;
};

class OutdoorsService {
  async getOutdoors(filters?: Filters) {
    const queryParams = new URLSearchParams();

    queryParams.set("q", filters?.search || "");
    queryParams.set("city", filters?.city || "");
    queryParams.set("neighborhood", filters?.neighborhood || "");
    queryParams.set("board_type", filters?.boardType || "");

    return HttpClient.get<Outdoor[]>(`/outdoor/?${queryParams.toString()}`);
  }
}

export default new OutdoorsService();
