import { useEffect, useState } from "react";
import { Center, Image, Input, Link, CenterProps } from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

import { useOutdoor } from "../context/outdoor";

import { useDebounce } from "../hooks/useDebounce";

// @ts-ignore
import ALinhares from "../assets/img/logo.png";

type HeaderProps = CenterProps;

export function Header({ ...props }: HeaderProps) {
  const { changeSearchValue } = useOutdoor();

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search);

  useEffect(() => {
    changeSearchValue(debouncedSearch);
  }, [debouncedSearch, changeSearchValue]);

  return (
    <Center
      as="header"
      justifyContent="space-between"
      w="100%"
      h={20}
      px={8}
      bg="white"
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      borderBottomColor="gray.300"
      {...props}
    >
      <Center flex={1} justifyContent="flex-start">
        <Search2Icon boxSize={5} strokeWidth={0.5} color="orange.500" />
        <Input
          placeholder="Pesquise por outdoors..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          flex={1}
          h={8}
          ml={5}
          fontSize={20}
          fontWeight={400}
          bg="transparent"
          color="gray.600"
          border={0}
          focusBorderColor="transparent"
          sx={{
            caretColor: "var(--chakra-colors-orange-500)",
          }}
          _placeholder={{
            color: "gray.600",
          }}
        />
      </Center>

      <Link
        href="https://alinhares.com.br/"
        title="Voltar ao site principal"
        h="60px"
        marginLeft={8}
      >
        <Image src={ALinhares} alt="Logotipo" w="auto" h="100%" />
      </Link>
    </Center>
  );
}
