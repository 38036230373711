import HttpClient from "./utils/HttpClient";

export type BoardTypesType = {
  id: number;
  name: string;
};

class BoardTypesService {
  async listBoardTypes() {
    return HttpClient.get<BoardTypesType[]>("/board-type");
  }
}

export default new BoardTypesService();
