import {
  Button,
  Center,
  Flex,
  Icon,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ControlPosition } from "leaflet";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { BiArrowBack } from "react-icons/bi";
import { TbFilter, TbFilterOff } from "react-icons/tb";
import { useSearchParams } from "react-router-dom";

import { useOutdoor } from "../../context/outdoor";

import { Spinner } from "../../components/Spinner";
import { Header } from "../../components/Header";
import { Sidebar } from "../../components/Sidebar";
import { CustomMarker } from "../../components/CustomMarker";
import { OutdoorDetails } from "../../components/OutdoorDetails";
import { FilterModal } from "../../components/FilterModal";
import { ImageModal } from "../../components/ImageModal";

import { mapStyles } from "./styles";

function Home() {
  const {
    mapRef,
    defaultPosition,
    defaultZoom,
    outdoors,
    isFirstRender,
    isLoading,
    isFilterModalVisible,
    setIsFilterModalVisible,
    isFiltersEmpty,
    isImageModalOpened,
    setIsImageModalOpened,
    selectedOutdoor,
    isOutdoorDetailsOpened,
    setIsOutdoorDetailsOpened,
  } = useOutdoor();

  const [, setSearchParams] = useSearchParams();

  const zoomControlPosition = useBreakpointValue<ControlPosition>({
    base: "topleft",
    lg: "topright",
  });

  const buttonLabel = isFiltersEmpty ? "Filtrar" : "Remover filtros";

  function handleCustomMarkerButtonClick() {
    setIsOutdoorDetailsOpened(true);
    setSearchParams({
      outdoor: selectedOutdoor ? String(selectedOutdoor.id) : "",
    });
  }

  if (isFirstRender && isLoading) {
    return (
      <Center w="100vw" h="100vh">
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <Flex flexDirection="column" w="100vw" h="100vh" position="relative">
        <Center
          display={{ base: "flex", lg: "none" }}
          position="absolute"
          top={4}
          right={4}
          zIndex={6}
        >
          <Button
            colorScheme="orange"
            aria-label={buttonLabel}
            title={buttonLabel}
            leftIcon={isFiltersEmpty ? <TbFilter /> : <TbFilterOff />}
            onClick={() => setIsFilterModalVisible(true)}
            fontWeight={600}
          >
            {buttonLabel}
          </Button>

          <Link
            href="https://alinhares.com.br/"
            title="Voltar ao site principal"
            display="flex"
            alignItems="center"
            justifyContent="center"
            w={10}
            h={10}
            ml={4}
            bg="blue.500"
            color="white"
            borderRadius="md"
            transition="background-color ease 0.3s"
            _hover={{
              bg: "var(--chakra-colors-blue-600)",
            }}
          >
            <Icon as={BiArrowBack} transform="rotate(180deg)" />
          </Link>
        </Center>

        <Header display={{ base: "none", lg: "flex" }} />

        <Flex as="main" flex={1} w="100%" height="calc(100vh - 80px)">
          <Sidebar display={{ base: "none", lg: "flex" }} />

          <Flex flex={1} sx={mapStyles} position="relative">
            {isLoading && (
              <Center
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                right={0}
                backgroundColor="rgba(255,255,255,0.7)"
                zIndex={899}
              >
                <Spinner />
              </Center>
            )}

            <MapContainer
              ref={mapRef}
              center={defaultPosition}
              zoom={defaultZoom}
              zoomControl={false}
              className="map-container"
            >
              <TileLayer
                url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
              />

              <ZoomControl position={zoomControlPosition} />

              {outdoors.map((outdoor) => (
                <CustomMarker
                  key={outdoor.code}
                  outdoor={outdoor}
                  onButtonClick={handleCustomMarkerButtonClick}
                />
              ))}
            </MapContainer>
          </Flex>
        </Flex>
      </Flex>

      <OutdoorDetails
        isOpen={isOutdoorDetailsOpened}
        onClose={() => setIsOutdoorDetailsOpened(false)}
      />

      <FilterModal
        isOpen={isFilterModalVisible}
        onClose={() => setIsFilterModalVisible(false)}
      />

      {selectedOutdoor?.image ? (
        <ImageModal
          isOpen={isImageModalOpened}
          onClose={() => setIsImageModalOpened(false)}
          image={selectedOutdoor.image.sizes.medium_large}
        />
      ) : null}
    </>
  );
}

export default Home;
