import { SystemStyleObject } from "@chakra-ui/react";

export const mapStyles: SystemStyleObject = {
  ".map-container": {
    w: "100%",
    h: "100%",

    zIndex: 5,

    ".leaflet-marker-icon": {
      svg: {
        // color: "var(--chakra-colors-gray-700)",
        color: "#f36b22",
      },

      "&.selected": {
        svg: {
          //   color: "var(--chakra-colors-blue-500)",
          color: "#1f285e",
        },
      },
    },

    ".mapPopup": {
      ".leaflet-popup-content-wrapper": {
        borderRadius: "var(--chakra-radii-lg)",
        boxShadow: "var(--chakra-shadows-lg)",

        ".leaflet-popup-content": {
          m: 0,
        },
      },

      ".leaflet-popup-tip-container": {
        ".leaflet-popup-tip": {
          boxShadow: "var(--chakra-shadows-lg)",
        },
      },
    },
  },
};
