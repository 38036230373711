import { useState } from "react";
import {
  Flex,
  FlexProps,
  Center,
  IconButton,
  useMediaQuery,
  Button,
} from "@chakra-ui/react";
import {
  TbChevronsLeft,
  TbChevronsRight,
  TbFilter,
  TbFilterOff,
} from "react-icons/tb";

import { useOutdoor } from "../context/outdoor";

import { OutdoorCard } from "./OutdoorCard";

import { customScrollBar } from "../styles/global";

type SidebarProps = FlexProps;

export function Sidebar({ ...props }: SidebarProps) {
  const { outdoors, setIsFilterModalVisible, isFiltersEmpty, isLoading } =
    useOutdoor();

  const [isExpanded, setIsExpanded] = useState(false);

  const [isLargerThan1400] = useMediaQuery("(min-width: 1400px)");

  const expandedSidebarWidth = isLargerThan1400 ? 950 : 650;

  return (
    <Flex
      as="aside"
      position="relative"
      flexDirection="column"
      w={!isExpanded ? 400 : expandedSidebarWidth}
      h="100%"
      px={3}
      py={10}
      bg="white"
      borderRightStyle="solid"
      borderRightWidth="1px"
      borderRightColor="gray.300"
      transition="all 0.3s ease"
      {...props}
    >
      <IconButton
        size="sm"
        variant="solid"
        colorScheme="gray"
        position="absolute"
        top="44px"
        right={-4}
        zIndex={999}
        borderRadius="50%"
        border="solid 1px"
        borderColor="gray.300"
        aria-label={!isExpanded ? "Expandir" : "Comprimir"}
        title={!isExpanded ? "Expandir" : "Comprimir"}
        icon={!isExpanded ? <TbChevronsRight /> : <TbChevronsLeft />}
        onClick={() => setIsExpanded(!isExpanded)}
      />

      <Center px={5}>
        <Button
          variant="outline"
          size="md"
          colorScheme="orange"
          leftIcon={isFiltersEmpty ? <TbFilter /> : <TbFilterOff />}
          onClick={() => setIsFilterModalVisible(true)}
          width="full"
        >
          {isFiltersEmpty ? "Seleção por filtros" : "Remover filtros"}
        </Button>
      </Center>

      <Flex
        gap={5}
        h="100%"
        mt={6}
        px={5}
        overflowX="hidden"
        overflowY="auto"
        sx={customScrollBar}
        {...(!isExpanded
          ? { flexDirection: "column" }
          : {
              display: "grid",
              gridTemplateColumns: `repeat(${
                isLargerThan1400 ? "3" : "2"
              }, 280px)`,
            })}
      >
        {!isLoading &&
          outdoors.map((outdoor) => (
            <OutdoorCard key={outdoor.id} outdoor={outdoor} />
          ))}
      </Flex>
    </Flex>
  );
}
